<template>
  <div v-if="$laptop"
       class="header"
       :class="$viewport.windowScroll > 20
               || headerException
               || search
               ? 'header__background-white'
               : ''">
    <div class="header__wrapper">
      <Row>
        <Column class="header__logotype"
                :style="$city.slug === 'minvody' ? {background: 'white'} : {}">
          <slot name="header-logotype"/>
        </Column>
        <Row class="header__meta"
             justify="between">
          <Column class="header__search search"
                  v-if="search"
                  justify="center">
            <div class="search__wrapper">
              <input class="search__input"
                     :placeholder="$t('header.search')"
                     v-model="query"/>
            </div>
          </Column>
          <Column v-else
                  class="header__navigation"
                  justify="center">
            <Row v-click-outside="close"
                 class="header-navigation">
              <router-link class="header-navigation__link"
                           :to="`${$locale.base}/municipality`">{{ $t('header.menu.about') }}
              </router-link>
              <router-link class="header-navigation__link"
                           :to="`${$locale.base}/places`">{{ $t('header.menu.guide') }}
              </router-link>
              <Column class="relative">
                <span class="header-navigation__link"
                      v-on:click="toggleOne">{{ $t('header.menu.tours') }}</span>
                <transition name="fade">
                  <Column class="header-navigation__submenu"
                          v-if="showOne">
                    <router-link class="header-navigation__submenu-link"
                                 :to="`${$locale.base}/excursions`">
                      {{ $t('header.menu.excursions') }}
                    </router-link>
                    <router-link class="header-navigation__submenu-link"
                                 :to="`${$locale.base}/trips`">{{ $t('header.menu.routes') }}
                    </router-link>
                  </Column>
                </transition>
              </Column>
              <router-link class="header-navigation__link"
                           :to="`${$locale.base}/events`">{{ $t('header.menu.playbill') }}
              </router-link>
              <Column class="relative">
                <span class="header-navigation__link"
                      v-on:click="toggleTwo">{{ $t('header.menu.transport') }}</span>
                <transition name="fade">
                  <Column class="header-navigation__submenu"
                          v-if="showTwo">
                    <router-link class="header-navigation__submenu-link"
                                 :to="`${$locale.base}/transport`">
                      {{ $t('header.menu.transport') }}
                    </router-link>
                    <router-link v-if="$showIfDefaultCity"
                                 class="header-navigation__submenu-link"
                                 :to="`${$locale.base}/transport-map`">
                      {{ $t('header.menu.transport_map') }}
                    </router-link>
                  </Column>
                </transition>
              </Column>
              <router-link class="header-navigation__link"
                           :to="`${$locale.base}/interactive-map`">{{ $t('header.menu.map') }}
              </router-link>
            </Row>
          </Column>
          <Row>
            <Column class="header__loup"
                    justify="center">
              <Icon class="header__icon-loupe"
                    v-on:click.native="() => search = !search"
                    :xlink="search ? 'close' : 'loupe'"
                    :height="'30px'"
                    :width="'30px'"
                    :fill="$viewport.windowScroll > 20
                           || headerException
                           || search
                           ? '#cdcdcd'
                           : '#ffffff'"
                    :viewport="search ? '0 0 20 20' : '0 0 24 24'"/>
            </Column>
            <Column class="header__avatar"
                    justify="center">
              <transition name="fade"
                          mode="out-in">
                <router-link v-if="$user.logged"
                             :to="`${$locale.base}/account`">
                  <UserAvatar :params="{avatar: $user.avatar, size: 48}"/>
                </router-link>
                <Button v-else color="main" v-on:click.native="$redirectIfGuest">
                  {{ $t('header.login') }}
                </Button>
              </transition>
            </Column>
            <Column class="header__sos"
                    justify="center">
              <router-link :to="`${$locale.base}/info`">
                <Icon viewport="0 0 48 48"
                      xlink="info-sos"/>
              </router-link>
            </Column>
            <Column class="header__lang"
                    justify="center">
              <Button class="header__lang-btn" v-on:click.native="openPopup">
                <Icon viewport="0 0 48 48"
                      xlink="lang"/>
              </Button>
            </Column>
          </Row>
        </Row>
      </Row>
    </div>
  </div>
  <Column v-else
          class="header-mobile">
    <Row class="header-mobile__wrapper"
         justify="between"
         align="center">
      <Row align="center">
        <router-link :to="`${$locale.base}/`"
                     class="header-mobile__logotype">
          <Icon xlink="logotype-mini"
                fill="#fff"
                viewport="0 0 48 48"/>
        </router-link>
        <div class="header-mobile__burger-menu"
             v-on:click="toggleMobile">
          <Icon class="header-mobile__burger-menu-icon"
                :viewport="showMobile ? '0 0 20 20' : '0 0 24 24'"
                :xlink="showMobile ? 'close' : 'burger-menu'"/>
        </div>
      </Row>
      <div class="header-mobile__avatar-wrapper">
        <transition name="fade"
                    mode="out-in">
          <UserAvatar v-if="$user.logged"
                      class="header-mobile__avatar"
                      v-on:click.native="$openPopupMobileMenu"
                      :params="{avatar: $user.avatar, size: 48}"/>
          <a class="header-mobile__sign-in"
             :href="`/login/keycloak?latest_url=${window.location.host}${this.$route.fullPath}`"
             v-else>{{ $t('header.login') }}</a>
        </transition>
      </div>
    </Row>
    <transition name="fade"
                mode="out-in">
      <div class="header-mobile__menu"
           v-if="showMobile">
        <Column class="header-mobile__menu-wrapper">
          <router-link class="header-mobile__link"
                       :to="`${$locale.base}/municipality`">{{ $t('header.menu.about') }}
          </router-link>
          <router-link class="header-mobile__link"
                       :to="`${$locale.base}/places`">{{ $t('header.menu.guide') }}
          </router-link>
          <router-link class="header-mobile__link"
                       :to="`${$locale.base}/excursions`">{{ $t('header.menu.excursions') }}
          </router-link>
          <router-link class="header-mobile__link"
                       :to="`${$locale.base}/trips`">{{ $t('header.menu.routes') }}
          </router-link>
          <router-link class="header-mobile__link"
                       :to="`${$locale.base}/events`">{{ $t('header.menu.playbill') }}
          </router-link>
          <router-link class="header-mobile__link"
                       :to="`${$locale.base}/transport`">{{ $t('header.menu.transport') }}
          </router-link>
          <router-link class="header-mobile__link"
                       :to="`${$locale.base}/interactive-map`">{{ $t('header.menu.map') }}
          </router-link>
          <router-link class="header-mobile__link"
                       :to="`${$locale.base}/info`">{{ $t('header.menu.info') }}
          </router-link>
          <Row justify="between"
               wrap>
            <a class="grid-sm-full grid-md-4 home-view__app-icon"
               :href="appStoreHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/appstore.svg')" alt/>
            </a>
            <a class="grid-sm-full grid-md-4 home-view__app-icon"
               :href="googlePlayHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/googleplay.svg')" alt/>
            </a>
          </Row>
        </Column>
      </div>
    </transition>
  </Column>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      search: false,
      query: '',
      showOne: false,
      showTwo: false,
      showMobile: false,
      appStoreHref: process.env.VUE_APP_DEFAULTH_MOBILE_APPLE_LINK,
      googlePlayHref: process.env.VUE_APP_DEFAULTH_MOBILE_ANDROID_LINK,
    };
  },
  methods: {
    openPopup() {
      this.$store.commit('OPEN_POPUP', {
        width: this.$laptop ? 730 : '100%',
        height: this.$laptop ? 370 : '100%',
        type: 'PopupChangeLang',
      });
    },
    toggleMobile() {
      this.showMobile = !this.showMobile;
    },
    toggleOne() {
      this.showTwo = false;
      this.showOne = !this.showOne;
    },
    toggleTwo() {
      this.showOne = false;
      this.showTwo = !this.showTwo;
    },
    close() {
      this.showOne = false;
      this.showTwo = false;
    },
  },
  computed: {
    headerException() {
      return [
        'interactive-map',
        'news',
        'events',
        'stories',
        'account',
      ].some((item) => (this.$i18n.locale === 'ru' ? item === this.$route.path.split('/')[1] : item === this.$route.path.split('/')[2]));
    },
  },
  watch: {
    $route() {
      this.close();
      this.showMobile = false;
    },
    search() {
      if (!this.search) this.$store.commit('CLEAR_SEARCH');
    },
    query() {
      if (this.query.length > 3) {
        this.$store.dispatch('REQUEST_SEARCH_FROM_SERVER', {
          params: {
            query: this.query,
            city_id: this.$city.id,
          },
        });
      } else {
        this.$store.commit('CLEAR_SEARCH');
      }
    },
  },
};
</script>
